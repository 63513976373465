



















import {Component, Vue} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import PaymentForm from "@/components/for-payments-view/PaymentForm.vue";
import PaymentDisabled from "@/components/for-payments-view/PaymentDisabled.vue";
import HelpCard from "@/components/for-payments-view/HelpCard.vue";
import {mapGetters, mapState} from "vuex";
import {paymentForm} from "@/assets/scripts/form-fields/main";

const { values } = paymentForm;

@Component({
  components: { Page, PaymentForm, HelpCard, PaymentDisabled },
  computed: {
    ...mapState({ appEnviroment: "appEnviroment" }),
    ...mapGetters({
      email: "user/email",
      contractInactive: "contract/inactive",
      contractClosed: "contract/closed",
    }),
  },
})
class Payments extends Vue {
  [x: string]: any
  values = values

  mounted() {
    this.values = {...this.values, email: this.userEmail, amount: this.amount, total: this.total};
    this.defaultValues = {...this.values, email: this.userEmail, amount: this.amount, total: this.total};
  }

  get paymentDisabled() {
    return this.contractInactive || this.contractClosed;
  }

  get amount() {
    return this.$route.params.amount || 0
  }

  get total() {
    const { appEnviroment } = this;
    return +this.amount + (+this.amount * (+appEnviroment.constants["PaymentFee"] || 0))
  }

  get userEmail() {
    return this.email
  }

  get docs() {
    return this.$route.params.docs || '[]'
  }
}

export default Payments;
